import { DAY } from '@vuddy/utils';
import { removeCookie, setCookie } from '@vuddy/utils/src/cookie';
import { env } from 'next-runtime-env';

export const getAccessTokenCookieName = () => {
  const phase = env('NEXT_PUBLIC_PHASE');
  return phase === 'production' ? 'accessToken' : `accessToken_${phase}`;
};

const DefaultOption = {
  path: '/',
  domain: env('NEXT_PUBLIC_AUTH_COOKIE_DOMAIN'),
};

export const setToken = (accessToken: string) => {
  if (accessToken) {
    const expireDate = new Date(Date.now() + DAY * 2);
    const options = {
      ...DefaultOption,
      expires: expireDate,
    };
    setCookie(getAccessTokenCookieName(), accessToken, options);
  }
};

export const removeToken = () => {
  removeCookie(getAccessTokenCookieName(), DefaultOption);
};
