import { env } from 'next-runtime-env';

export const URLS = {
  FAQ: `${env('NEXT_PUBLIC_ZENDESK_URL')}/hc/ko/categories/10507116197903`,
  INQUIRY: `${env('NEXT_PUBLIC_ZENDESK_URL')}/hc/ko/requests/new`,
  NOTICE: `${env('NEXT_PUBLIC_ZENDESK_URL')}/hc/ko/categories/10507149112463`,
  TERMS_AND_CONDITIONS: `${env('NEXT_PUBLIC_ZENDESK_URL')}/hc/ko/articles/10507145510415`,
  PRIVACY_POLICY: `${env('NEXT_PUBLIC_ZENDESK_URL')}/hc/ko/articles/10507159804687`,
  BIZ_INFO_CHECK: `http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5738400041`,
  CREATOR_APPLICATION_FORM:
    env('NEXT_PUBLIC_PHASE') === 'production'
      ? `https://docs.google.com/forms/d/e/1FAIpQLSfX7Q_x0xaxJTEhe02pcQMmkYiArdoaEES9JHSWpGZ1nIc3-g/viewform`
      : `https://docs.google.com/forms/d/e/1FAIpQLScc8S7W7eJ6ab_Pl_gsGiCrLo88_YzEebvmvaB1wLsYCU8-iw/viewform?usp=sf_link`,
};
