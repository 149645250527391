import { CurrencyCode } from '../types';

const currencyText: {
  [key in CurrencyCode]: string;
} = {
  KRW: '원',
} as const;

export const getCurrencyText = (currency: CurrencyCode): string =>
  currencyText[currency];
