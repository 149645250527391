import { ClearButton, ClearButtonProps } from '../clearButton';
import { IIconProps, Icon } from '../icon';

interface IconButtonProps extends ClearButtonProps {
  icon: IIconProps;
}

export const IconButton = ({ icon, ...buttonProps }: IconButtonProps) => {
  return (
    <ClearButton {...buttonProps}>
      <Icon {...icon} />
    </ClearButton>
  );
};
