'use client';

import { IconName, Row } from '@vuddy/components';
import type { TagVariants } from './tag.css';
import * as styles from './tag.css';

export type TagProps = TagVariants & {
  iconName?: IconName;
  label?: string;
};

export const Tag = ({
  size = 'small',
  variant = 'blue',
  iconName,
  label,
}: TagProps) => {
  return (
    <Row
      display="inline-flex"
      alignItems="center"
      gap="gap_2xs"
      flex="none"
      className={styles.tag({ variant, size })}>
      {label}
    </Row>
  );
};
