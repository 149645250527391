'use client';

import { createContext, ReactNode, useEffect, useState } from 'react';

export const HistoryIndexContext = createContext<number>(0);

export const HistoryIndexProvider = ({ children }: { children: ReactNode }) => {
  const [historyIndex, setHistoryIndex] = useState(0);

  useEffect(() => {
    const preservedPushState = window.history.pushState;
    const preservedReplaceState = window.history.replaceState;

    window.history.pushState = function (
      data: any,
      unused: string,
      url?: string | URL | null
    ) {
      setHistoryIndex(historyIndex => {
        const nextIndex = historyIndex + 1;
        data.myIndex = nextIndex;
        return nextIndex;
      });
      preservedPushState.apply(this, [data, unused, url]);
    };

    window.history.replaceState = function (
      data: any,
      unused: string,
      url?: string | URL | null
    ) {
      data.myIndex = historyIndex;
      preservedReplaceState.apply(this, [data, unused, url]);
    };

    return () => {
      window.history.pushState = preservedPushState;
      window.history.replaceState = preservedReplaceState;
    };
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      (document.activeElement as HTMLElement)?.blur();
      (document.body as HTMLBodyElement)?.focus();
      setHistoryIndex(history.state?.myIndex ?? 0);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <HistoryIndexContext.Provider value={historyIndex}>
      {children}
    </HistoryIndexContext.Provider>
  );
};
