import { jsonAssignInlineVars } from '@vuddy/utils';
import { forwardRef } from 'react';
import { ElementProps } from '../element';
import { Row } from '../flex/Row';
import { Lottie } from '../lottie';
import * as styles from './loadingSpinner.css';
import spinner from './spinner.json';

interface LoadingSpinnerProps {
  size?: string;
  width?: ElementProps['width'];
}

export const LoadingSpinner = forwardRef<HTMLDivElement, LoadingSpinnerProps>(
  ({ size = '50px', width = '100%' }, ref) => {
    return (
      <Row width={width} alignItems="center" justifyContent="center" ref={ref}>
        <div
          className={styles.lottieWrapper}
          style={jsonAssignInlineVars({
            [styles.sizeVar]: size,
          })}>
          <Lottie name="loadingSpinner" animationData={spinner} autoplay loop />
        </div>
      </Row>
    );
  }
);
