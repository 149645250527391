import { URLS } from '@/urls';
import { Column, Element, Icon, Row } from '@vuddy/components';
import * as styles from './commonFooter.css';

export const CommonFooter = () => {
  return (
    <Column
      backgroundColor="quaternary"
      width="100%"
      marginTop="auto"
      paddingTop="padding_xl"
      paddingBottom="padding_3xl"
      paddingX="padding_large"
      gap="gap_3xl">
      <Icon
        name="ETC_VUDDYWORDMARK"
        width="76px"
        height="18px"
        color="tertiary"
      />
      <Column gap="gap_2xl">
        <Column gap="gap_xs" color="tertiary">
          <Row gap="gap_base">
            <Row gap="gap_xs">
              <Element typography="captionBaseAccent">상호</Element>
              <Element typography="captionBase">레벨스 인크 (영업소)</Element>
            </Row>
            <Row gap="gap_xs">
              <Element typography="captionBaseAccent">대표자</Element>
              <Element typography="captionBase">김기강</Element>
            </Row>
          </Row>

          <Row gap="gap_xs">
            <Element typography="captionBaseAccent">사업장 주소</Element>
            <Element typography="captionBase">
              서울 강남구 테헤란로4길 14 7층
            </Element>
          </Row>

          <Row gap="gap_base">
            <Row gap="gap_xs">
              <Element typography="captionBaseAccent">사업자 등록 번호</Element>
              <Element typography="captionBase">573-84-00041</Element>
            </Row>
            <Row gap="gap_xs">
              <Element typography="captionBaseAccent">
                개인정보 관리 책임자
              </Element>
              <Element typography="captionBase">이윤혁</Element>
            </Row>
          </Row>
          <Row gap="gap_xs">
            <Element typography="captionBaseAccent">
              통신판매업 신고번호
            </Element>
            <Element typography="captionBase">
              제 2024-서울강남- 04089 호
            </Element>
          </Row>
          <Row gap="gap_xs">
            <Element typography="captionBaseAccent">대표 번호</Element>
            <Element typography="captionBase">02-597-4815</Element>
          </Row>
        </Column>
        <Row gap="gap_base" typography="captionBaseAccent">
          <Element color="tertiary">
            <a href={URLS.TERMS_AND_CONDITIONS} target="_blank">
              이용약관
            </a>
          </Element>
          <Element color="tertiary" className={styles.divider}>
            |
          </Element>
          <Element color="primary">
            <a href={URLS.PRIVACY_POLICY} target="_blank">
              개인정보처리방침
            </a>
          </Element>
          <Element color="tertiary" className={styles.divider}>
            |
          </Element>
          <Element color="tertiary">
            <a href={URLS.BIZ_INFO_CHECK} target="_blank">
              사업자정보 확인 링크
            </a>
          </Element>
        </Row>
        <Element typography="captionBase" color="tertiary">
          일부 상품의 경우 레벨스는 통신판매의 당사자가 아닌 통신판매중개자로서
          상품, 상품정보, 거래에 대한 책임이 제한될 수 있으므로, 각 상품
          페이지에서 구체적인 내용을 확인하시기 바랍니다.
        </Element>
        <Element typography="captionBaseAccent" color="tertiary">
          © 2024 LEVVELS Inc. All Rights Reserved
        </Element>
      </Column>
    </Column>
  );
};
