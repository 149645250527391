'use client';

import { Element, ElementProps } from '@vuddy/components';
import { isNil, range } from 'lodash-es';
import { ReactNode, useEffect, useRef } from 'react';
import * as styles from './slide.css';

function buildThresholdList() {
  return [...range(1, 20).map(step => step / 20), 0];
}

export interface HorizontalScrollbarSlideProps {
  children: ReactNode;
  className?: string;
  slideWidth?: ElementProps['width'];
  onVisibleInViewport: VoidFunction;
}

export const HorizontalScrollbarSlide = ({
  children,
  slideWidth,
  onVisibleInViewport,
}: HorizontalScrollbarSlideProps) => {
  const slideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slideElement = slideRef.current;

    if (isNil(slideElement)) return;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio !== 1) return;
        onVisibleInViewport();
      },
      { threshold: 1 }
    );

    observer.observe(slideElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Element
      className={styles.slide}
      width={slideWidth}
      flex="none"
      ref={slideRef}>
      {children}
    </Element>
  );
};
