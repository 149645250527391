import React, { forwardRef } from 'react';
import * as styles from './inputCodeBox.css';

interface IInputCodeBoxProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const InputCodeBox = forwardRef<HTMLInputElement, IInputCodeBoxProps>(
  ({ value, onChange, onKeyDown }, ref) => (
    <input
      ref={ref}
      type="text"
      maxLength={1}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={styles.inputCodeBox}
    />
  )
);
