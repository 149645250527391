import clsx from 'clsx';
import { ReactNode, cloneElement, isValidElement } from 'react';
import * as buttonStyles from '../button/button.css';

export type AsButtonStyleProps = buttonStyles.ButtonVariants & {
  children: ReactNode;
};

export const AsButtonStyle = ({
  children,
  fullWidth,
  size,
  variant,
}: AsButtonStyleProps) => {
  if (!isValidElement(children)) {
    return null;
  }

  return cloneElement(children, {
    ...children.props,
    className: clsx(
      buttonStyles.button({ fullWidth, size, variant }),
      children.props.className
    ),
  });
};
