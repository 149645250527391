'use client';

import { IOrderHistorySearchResponseOrderItem } from '@/types';
import { getCurrencyText } from '@/utils/commonUtils';
import { ClearButton, Column, Element, Ellipsis, Row } from '@vuddy/components';
import { line, radiusSize } from '@vuddy/components/theme';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React from 'react';
import { getOrderProductTypeText } from '../orderUtils';

interface IOrderProductProps
  extends Pick<
    IOrderHistorySearchResponseOrderItem,
    'thumbnail' | 'referenceType' | 'name' | 'description' | 'totalPrice'
  > {
  children?: React.ReactNode;
  link?: string;
}

export const OrderProduct = ({
  thumbnail,
  referenceType = 'BASIC_MEDIA',
  name,
  description,
  totalPrice,
  children,
  link,
}: IOrderProductProps) => {
  const router = useRouter();

  const onClickButton = () => {
    if (link) {
      router.push(link);
    }
  };

  return (
    <div>
      <Row gap="gap_base">
        <ClearButton onClick={onClickButton}>
          <Image
            src={thumbnail.url}
            alt="product image"
            width={72}
            height={72}
            style={{
              borderRadius: radiusSize.radius_base,
              border: `1px solid ${line.imgOutline}`,
            }}
          />
        </ClearButton>
        <Column gap="gap_xs" flex={1}>
          <ClearButton onClick={onClickButton} fullWidth>
            <Column gap="gap_2xs" alignItems="start">
              <Column
                width="fit-content"
                height={20}
                justifyContent="center"
                paddingX="padding_4xs"
                backgroundColor="black"
                borderRadius="radius_xs"
                typography="captionXsAccent"
                color="whitePrimary">
                {getOrderProductTypeText(referenceType)}
              </Column>
              <Ellipsis
                typography="captionBaseAccent"
                colorToken="secondary"
                lineClamp={2}>
                {name}
              </Ellipsis>
              <Ellipsis
                typography="captionBase"
                colorToken="tertiary"
                textAlign="left"
                lineClamp={3}>
                {description}
              </Ellipsis>
              <Element typography="bodyXsAccent" color="primary">
                {totalPrice.amount.toLocaleString()}
                {getCurrencyText(totalPrice.currencyCode)}
              </Element>
            </Column>
          </ClearButton>
          {children}
        </Column>
      </Row>
    </div>
  );
};
