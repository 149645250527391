import { Ellipsis, Row } from '@vuddy/components';
import {
  SurfaceColorToken,
  TypographyKey,
  theme,
} from '@vuddy/components/theme';
import { jsonAssignInlineVars } from '@vuddy/utils';
import { ReactNode } from 'react';
import * as styles from './navigationBar.css';

export interface INavigationBarProps {
  title?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  isTransparent?: boolean;
  backgroundColor?: SurfaceColorToken;
  typography?: TypographyKey;
}

export const NavigationBar = ({
  title,
  left,
  right,
  isTransparent = false,
  backgroundColor = 'primary',
  typography = 'bodyBaseAccent',
}: INavigationBarProps) => {
  return (
    <Row
      className={styles.wrapper({ isTransparent })}
      style={jsonAssignInlineVars({
        [styles.backgroundColorVar]: theme.surface[backgroundColor],
      })}>
      <div>{left}</div>
      <Ellipsis className={styles.center} typography={typography}>
        {title}
      </Ellipsis>
      <div>{right}</div>
    </Row>
  );
};
