import { jsonAssignInlineVars } from '@vuddy/utils';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import React, { ComponentType, createElement, forwardRef } from 'react';
import { Row } from '../flex/Row';
import { icon, theme } from '../theme/theme.css';
import * as styles from './icon.css';

export type PreLoadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<void>;
};

// https://github.com/ianschmitz/react-lazy-with-preload/blob/master/src/index.ts
export default function lazyWithPreload<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreLoadableComponent<T> {
  const LazyComponent = React.lazy(factory);
  let factoryPromise: Promise<void> | undefined;
  let LoadedComponent: T | undefined;

  const Component = forwardRef(function (props, ref) {
    return createElement(
      LoadedComponent ?? LazyComponent,
      Object.assign(ref ? { ref } : {}, props) as any
    );
  }) as any as PreLoadableComponent<T>;

  Component.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factory().then(module => {
        LoadedComponent = module.default;
      });
    }

    return factoryPromise;
  };
  return Component;
}

const loadIcon = (iconFile: string) =>
  lazyWithPreload(() => import(`./icons/${iconFile}.svg?react`));

export const icons = {
  ETC_ERRORGRAPHIC_64: loadIcon('etc-errorgraphic'),
  ETC_IMAGE_BLANK_80: loadIcon('etc-image-blank'),
  ETC_LOGO_APPLE_24: loadIcon('etc-logo-apple'),
  ETC_LOGO_GOOGLE_24: loadIcon('etc-logo-google'),
  ETC_LOGO_KAKAOPAY: loadIcon('etc-logo-kakaopay'),
  ETC_LOGO_NAVERPAY: loadIcon('etc-logo-naverpay'),
  ETC_LOGO_PAYCO: loadIcon('etc-logo-payco'),
  ETC_LOGO_SAMSUNGPAY: loadIcon('etc-logo-samsungpay'),
  ETC_LOGO_VUDDY_24: loadIcon('etc-logo-vuddy'),
  ETC_LOGO_NAVER_24: loadIcon('etc-logo-naver'),
  ETC_PROFILE_BLANK_80: loadIcon('etc-profile-blank'),
  ETC_VUDDYWORDMARK: loadIcon('etc-vuddywordmark'),

  FILL_BOX_24: loadIcon('fill-box'),
  FILL_CAMERA_24: loadIcon('fill-camera'),
  FILL_CHAT_24: loadIcon('fill-chat'),
  FILL_CHECK_CIRCLE_24: loadIcon('fill-check-circle'),
  FILL_CLOSE_CIRCLE_24: loadIcon('fill-close-circle'),
  FILL_DIGITALCARD_24: loadIcon('fill-digitalcard'),
  FILL_EDIT_24: loadIcon('fill-edit'),
  FILL_HOME_24: loadIcon('fill-home'),
  FILL_HOUSE_24: loadIcon('fill-house'),
  FILL_INFORMATION_CIRCLE_24: loadIcon('fill-information-circle'),
  FILL_LIKE_24: loadIcon('fill-like'),
  FILL_MINUS_CIRCLE_24: loadIcon('fill-minus-circle'),
  FILL_NEW_24: loadIcon('fill-new'),
  FILL_NOTIFICATION_24: loadIcon('fill-notification'),
  FILL_PAUSE_24: loadIcon('fill-pause'),
  FILL_PERSON_24: loadIcon('fill-person'),
  FILL_PHOTO_24: loadIcon('fill-photo'),
  FILL_PIN_24: loadIcon('fill-pin'),
  FILL_PLAY_24: loadIcon('fill-play'),
  FILL_PLUS_CIRCLE_24: loadIcon('fill-plus-circle'),
  FILL_RANK_24: loadIcon('fill-rank'),
  FILL_SEND_24: loadIcon('fill-send'),
  FILL_SHOP_24: loadIcon('fill-shop'),
  FILL_SKIP_24: loadIcon('fill-skip'),
  FILL_SORTING_24: loadIcon('fill-sorting'),
  FILL_TRASH_24: loadIcon('fill-trash'),
  FILL_TRIANGLE_DOWN_24: loadIcon('fill-triangle-down'),
  FILL_TRIANGLE_LEFT_24: loadIcon('fill-triangle-left'),
  FILL_TRIANGLE_RIGHT_24: loadIcon('fill-triangle-right'),
  FILL_TRIANGLE_UP_24: loadIcon('fill-triangle-up'),
  FILL_VERIFIED_24: loadIcon('fill-verified'),
  FILL_VIDEO_24: loadIcon('fill-video'),
  FILL_WARNING_24: loadIcon('fill-warning'),

  LINK_LOGO_DEFAULT: loadIcon('link-logo-default'),
  LINK_LOGO_CHZZK: loadIcon('link-logo-chzzk'),
  LINK_LOGO_X: loadIcon('link-logo-x'),
  LINK_LOGO_INSTAGRAM: loadIcon('link-logo-instagram'),
  LINK_LOGO_AFREECATV: loadIcon('link-logo-afreecatv'),
  LINK_LOGO_DISCORD: loadIcon('link-logo-discord'),
  LINK_LOGO_YOUTUBE: loadIcon('link-logo-youtube'),
  LINK_LOGO_NAVERCAFE: loadIcon('link-logo-navercafe'),

  OUTLINE_ARROW_DOWN_24: loadIcon('outline-arrow-down'),
  OUTLINE_ARROW_IOSBACK_24: loadIcon('outline-arrow-iosback'),
  OUTLINE_ARROW_LEFT_24: loadIcon('outline-arrow-left'),
  OUTLINE_ARROW_RIGHT_24: loadIcon('outline-arrow-right'),
  OUTLINE_ARROW_UP_24: loadIcon('outline-arrow-up'),
  OUTLINE_BOX_24: loadIcon('outline-box'),
  OUTLINE_CALENDAR_24: loadIcon('outline-calendar'),
  OUTLINE_CAMERA_24: loadIcon('outline-camera'),
  OUTLINE_CARD_24: loadIcon('outline-card'),
  OUTLINE_CART_24: loadIcon('outline-cart'),
  OUTLINE_CHANNEL_24: loadIcon('outline-channel'),
  OUTLINE_CHAT_24: loadIcon('outline-chat'),
  OUTLINE_CHECK_CIRCLE_24: loadIcon('outline-check-circle'),
  OUTLINE_CHECK_24: loadIcon('outline-check'),
  OUTLINE_CHEVRON_DOWN_24: loadIcon('outline-chevron-down'),
  OUTLINE_CHEVRON_LEFT_24: loadIcon('outline-chevron-left'),
  OUTLINE_CHEVRON_RIGHT_24: loadIcon('outline-chevron-right'),
  OUTLINE_CHEVRON_UP_24: loadIcon('outline-chevron-up'),
  OUTLINE_CHEVRON_UPDOWN_24: loadIcon('outline-chevron-updown'),
  OUTLINE_CLIPBOARD_24: loadIcon('outline-clipboard'),
  OUTLINE_CLOSE_24: loadIcon('outline-close'),
  OUTLINE_COPY_24: loadIcon('outline-copy'),
  OUTLINE_DASHBOARD_24: loadIcon('outline-dashboard'),
  OUTLINE_DOWNLOAD_24: loadIcon('outline-download'),
  OUTLINE_EDIT_24: loadIcon('outline-edit'),
  OUTLINE_ELLIPSIS_HORIZON_24: loadIcon('outline-ellipsis-horizon'),
  OUTLINE_ELLIPSIS_VERTICAL_24: loadIcon('outline-ellipsis-vertical'),
  OUTLINE_EMAIL_24: loadIcon('outline-email'),
  OUTLINE_EXPEND_24: loadIcon('outline-expend'),
  OUTLINE_FAQ_24: loadIcon('outline-faq'),
  OUTLINE_FOLLOWER_24: loadIcon('outline-follower'),
  OUTLINE_HEADPHONE_24: loadIcon('outline-headphone'),
  OUTLINE_INFORMATION_24: loadIcon('outline-information'),
  OUTLINE_LIKE_24: loadIcon('outline-like'),
  OUTLINE_LINK_24: loadIcon('outline-link'),
  OUTLINE_LOCK_24: loadIcon('outline-lock'),
  OUTLINE_LOWRANK_24: loadIcon('outline-lowrank'),
  OUTLINE_MESSAGE_24: loadIcon('outline-message'),
  OUTLINE_MONEY_24: loadIcon('outline-money'),
  OUTLINE_MUTE_24: loadIcon('outline-mute'),
  OUTLINE_NOPE_24: loadIcon('outline-nope'),
  OUTLINE_NOTIFICATION_24: loadIcon('outline-notification'),
  OUTLINE_PHOTO_24: loadIcon('outline-photo'),
  OUTLINE_PIN_24: loadIcon('outline-pin'),
  OUTLINE_PLUS_24: loadIcon('outline-plus'),
  OUTLINE_POST_24: loadIcon('outline-post'),
  OUTLINE_REDUCE_24: loadIcon('outline-reduce'),
  OUTLINE_RELOAD_24: loadIcon('outline-reload'),
  OUTLINE_REORDER_24: loadIcon('outline-reorder'),
  OUTLINE_SEARCH_24: loadIcon('outline-search'),
  OUTLINE_SETTING_24: loadIcon('outline-setting'),
  OUTLINE_SHARE_24: loadIcon('outline-share'),
  OUTLINE_SHOP_24: loadIcon('outline-shop'),
  OUTLINE_SIREN_24: loadIcon('outline-siren'),
  OUTLINE_SPEAKER_24: loadIcon('outline-speaker'),
  OUTLINE_STORE_24: loadIcon('outline-store'),
  OUTLINE_TIMER_24: loadIcon('outline-timer'),
  OUTLINE_TRASH_24: loadIcon('outline-trash'),
  OUTLINE_VERIFIED_24: loadIcon('outline-verified'),
  OUTLINE_VIDEO_24: loadIcon('outline-video'),
  OUTLINE_WARNING_24: loadIcon('outline-warning'),
} as const;

export type IconName = keyof typeof icons;
export type IconColor = keyof typeof icon;

const iconPreLoadedFlagEntries: Array<[IconName, boolean]> = Array.from(
  Object.keys(icons)
).map(iconName => [iconName as IconName, false] as const);

const iconPreLoadedMap = Object.fromEntries(iconPreLoadedFlagEntries);

const preloadIcons = (preloadIcons: IconName[]) => {
  preloadIcons
    .filter(iconName => !iconPreLoadedMap[iconName])
    .forEach(iconName => {
      iconPreLoadedMap[iconName] = true;
      icons[iconName].preload();
    });
};

export interface IIconProps {
  name: IconName;
  color?: IconColor;
  colorCode?: string;
  opacity?: number;
  preloadIconNames?: IconName[];
  rotate?: boolean;
  rotateTime?: number;
  uniqueKey?: string;
  size?: string;
  width?: string;
  height?: string;
  className?: string;
}

export const Icon = forwardRef<HTMLElement, IIconProps>(
  (
    {
      preloadIconNames = [],
      name,
      color,
      colorCode,
      opacity,
      rotate = false,
      rotateTime = 300,
      uniqueKey,
      size,
      width = size,
      height = size,
      className,
      ...props
    },
    forwardedRef
  ) => {
    const IconComponent = icons[name];

    const iconWidth = width ?? '24px';
    const iconHeight = height ?? '24px';

    preloadIcons(preloadIconNames);

    return (
      <Row
        ref={forwardedRef}
        className={clsx(styles.wrapper, className)}
        style={jsonAssignInlineVars({
          [styles.widthVar]: iconWidth,
          [styles.heightVar]: iconHeight,
          [styles.animationVar]:
            rotate && rotateTime
              ? `${styles.rotateKeyframes} ${rotateTime}ms infinite`
              : undefined,
        })}>
        <IconComponent
          key={uniqueKey}
          color={colorCode ?? isNil(color) ? undefined : theme.icon[color]}
          opacity={opacity}
          flex="none"
          title={[name, color ?? ''].join('-')}
          className={styles.icon}
          {...props}
        />
      </Row>
    );
  }
);
