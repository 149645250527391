import { Element, Icon, IconColor, IconName } from '@vuddy/components';
import * as styles from './checkboxIcon.css';

interface ICheckboxIconProps {
  checked: boolean;
  isPartialChecked?: boolean;
  disabled?: boolean;
}

export const CheckboxIcon = ({
  checked,
  isPartialChecked,
  disabled,
}: ICheckboxIconProps) => {
  const isChecked = checked || isPartialChecked;

  const iconName: IconName = isPartialChecked
    ? 'FILL_MINUS_CIRCLE_24'
    : 'FILL_CHECK_CIRCLE_24';

  const iconColor: IconColor = isChecked
    ? disabled
      ? 'vuddySecondary'
      : 'vuddyPrimary'
    : disabled
      ? 'disabled'
      : 'placeholder';

  return (
    <Element position="relative">
      <Icon name={iconName} color={iconColor} />
      <div className={styles.iconHoverShadow} />
    </Element>
  );
};
