import { request } from '@/lib/request';
import { useMutation } from '@/lib/useWrappingQuery';
import {
  IPincodeCreateRequest,
  IPincodeCreateResponse,
  IPincodeVerifyRequest,
  IPincodeVerifyResponse,
} from '@/types';
import { isNonNil } from '@vuddy/utils';

const requestEmailPincode = (params: IPincodeCreateRequest) => {
  return request.post<IPincodeCreateResponse>('/pincodes', params);
};

export const useRequestEmailPincode = ({
  redirectPathOnSuccess,
  onSuccess,
}: {
  redirectPathOnSuccess?: string;
  onSuccess?: (response: IPincodeCreateResponse) => void;
}) => {
  return useMutation({
    mutationFn: requestEmailPincode,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      }
      onSuccess?.(response.data);
    },
    redirectPathOnSuccess,
  });
};

const verifyEmailPincode = (params: IPincodeVerifyRequest) => {
  return request.put<IPincodeVerifyResponse>('/pincodes/verify', params);
};

export const useVerifyEmailPincode = ({
  onSuccess,
}: {
  onSuccess: (pincodeId: IPincodeVerifyResponse) => void;
}) => {
  return useMutation({
    mutationFn: verifyEmailPincode,
    onSuccess: response => {
      if (isNonNil(response.error)) {
        console.error(response.error);
      } else {
        onSuccess(response.data);
      }
    },
  });
};
