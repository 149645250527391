'use client';

import {
  Column,
  Element,
  IconButton,
  Input as SharedInput,
  InputProps as SharedInputProps,
} from '@vuddy/components';
import { dispatchInputEvent, isNonNil } from '@vuddy/utils';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { ChangeEvent, forwardRef, useRef, useState } from 'react';
import * as styles from './input.css';

export interface InputProps extends Omit<SharedInputProps, 'styles'> {
  styles?: {
    wrapper?: (params?: { disabled: boolean; error: boolean }) => string;
    input?: (params?: { disabled: boolean; error: boolean }) => string;
  };
  errorText?: string;
  clearable?: boolean;
  onClear?: VoidFunction;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value: controlledValue,
      disabled,
      onChange: controlledOnChange,
      initialValue = '',
      styles: customStyles,
      suffix,
      errorText,
      clearable = true,
      onClear,
      ...props
    },
    ref
  ) => {
    const [innerValue, setInnerValue] = useState(initialValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const value = controlledValue ?? innerValue;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      controlledOnChange
        ? controlledOnChange(e)
        : setInnerValue(e.target.value);
    };

    const handleClearClick = () => {
      const inputElement =
        (ref as React.RefObject<HTMLInputElement>)?.current || inputRef.current;

      if (isNonNil(inputElement)) {
        dispatchInputEvent('', inputElement);
        inputElement.focus();
        onClear?.();
      }
    };
    return (
      <Column width="100%" gap="gap_base">
        <SharedInput
          styles={{
            inputWrapper: params =>
              clsx(styles.wrapper(params), customStyles?.wrapper?.(params)),
            input: params => clsx(styles.input, customStyles?.input?.(params)),
          }}
          suffix={color =>
            isNonNil(suffix) ? (
              suffix?.(color)
            ) : !isEmpty(value) && !disabled ? (
              <IconButton
                icon={{ name: 'OUTLINE_CLOSE_24', color }}
                onClick={handleClearClick}
              />
            ) : undefined
          }
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={ref || inputRef}
          {...props}
        />
        {props.error && isNonNil(errorText) && (
          <Element typography="captionBase" color="danger">
            {errorText}
          </Element>
        )}
      </Column>
    );
  }
);
