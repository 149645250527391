import { getClientSideUserAgent, isIOSMobile } from '@vuddy/utils';
import { useEffect } from 'react';

export function useViewportSize(isAppWebView: boolean) {
  useEffect(() => {
    const setViewportProperties = () => {
      requestAnimationFrame(() => {
        if (!isAppWebView) {
          document.documentElement.style.setProperty(
            '--vh',
            `${window.innerHeight * 0.01}px`
          );
        }

        document.documentElement.style.setProperty(
          '--sat',
          isAppWebView && isIOSMobile(getClientSideUserAgent())
            ? `env(safe-area-inset-top)`
            : '0px'
        );
        document.documentElement.style.setProperty(
          '--sab',
          isAppWebView && isIOSMobile(getClientSideUserAgent())
            ? `env(safe-area-inset-bottom)`
            : '0px'
        );
      });
    };

    setViewportProperties();
    window.addEventListener('resize', setViewportProperties);

    return () => {
      window.removeEventListener('resize', setViewportProperties);
    };
  }, [isAppWebView]);
}
