'use client';

import { ElementProps, Row } from '@vuddy/components';
import { getClientSideUserAgent, isMobile } from '@vuddy/utils';
import { isNil } from 'lodash-es';
import { Children, ReactNode, useEffect, useRef, useState } from 'react';
import * as styles from './horizontalScrollbar.css';
import { HorizontalScrollbarSlide } from './slide/slide';

export interface HorizontalScrollbarProps {
  children: ReactNode;
  slideWidth?: ElementProps['width'];
  floating?: ReactNode;
  onActiveIndexChange?: (index: number) => void;
}

export const HorizontalScrollbar = ({
  children,
  slideWidth = '100%',
  floating,
  onActiveIndexChange,
}: HorizontalScrollbarProps) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef(false);
  const dragStart = useRef<{
    mouseXInSlide: number;
    carouselScrollLeft: number;
  } | null>(null);
  const childrenArray = Children.toArray(children);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleVisibleInViewport = (index: number) => {
    setActiveIndex(index);
    onActiveIndexChange?.(index);
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    if (isNil(carousel) || isMobile(getClientSideUserAgent())) return;

    const handleMouseDown = (e: MouseEvent): void => {
      isDragging.current = true;
      dragStart.current = {
        mouseXInSlide: e.pageX - carousel.offsetLeft,
        carouselScrollLeft: carousel.scrollLeft,
      };
      carousel.classList.add(styles.dragging);
    };

    const handleMouseLeave = () => {
      isDragging.current = false;

      carousel.classList.remove(styles.dragging);
    };
    const handleMouseUp = () => {
      isDragging.current = false;

      carousel.classList.remove(styles.dragging);
    };
    const handleMouseMove = (e: MouseEvent): void => {
      if (!isDragging.current || isNil(dragStart.current)) return;
      e.preventDefault();
      const mouseXInSlide = e.pageX - carousel.offsetLeft;
      const dragDelta = mouseXInSlide - dragStart.current.mouseXInSlide;

      carousel.scrollLeft =
        dragStart.current.carouselScrollLeft - dragDelta * 2;
    };

    carousel.addEventListener('mousedown', handleMouseDown);
    carousel.addEventListener('mouseleave', handleMouseLeave);
    carousel.addEventListener('mouseup', handleMouseUp);
    carousel.addEventListener('mousemove', handleMouseMove);

    return () => {
      carousel.removeEventListener('mousedown', handleMouseDown);
      carousel.removeEventListener('mouseleave', handleMouseLeave);
      carousel.removeEventListener('mouseup', handleMouseUp);
      carousel.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Row className={styles.wrapper} ref={carouselRef}>
      {childrenArray.map((children, index) => (
        <HorizontalScrollbarSlide
          slideWidth={slideWidth}
          key={index}
          onVisibleInViewport={() => handleVisibleInViewport(index)}>
          {children}
        </HorizontalScrollbarSlide>
      ))}
      {floating}
    </Row>
  );
};
