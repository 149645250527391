import {
  Confirm,
  IConfirmProps,
  ITextFieldConfirmProps,
  TextFieldConfirm,
} from '@/components/modals';
import { OverlayProps, useOverlay } from '@vuddy/components';

export type IConfirm = (params: IConfirmProps & OverlayProps) => void;

export const useModal = () => {
  const { openModal } = useOverlay();

  const confirm: IConfirm = ({
    cancelText,
    confirmText,
    description,
    title,
    onConfirm,
    onCancel,
    hasCloseButton,
    hasCancel,
    isWarningStyle,
  }: IConfirmProps & OverlayProps) => {
    openModal({
      component: Confirm,
      props: {
        cancelText,
        confirmText,
        description,
        title,
        onConfirm,
        onCancel,
        hasCloseButton,
        hasCancel,
        isWarningStyle,
        style: {
          container: {
            width: '296px',
          },
        },
      },
      allowDuplicate: false,
    });
  };

  const textFieldConfirm = ({
    cancelText,
    confirmText,
    description,
    title,
    regex,
    initialValue,
    errorText,
    onConfirm,
    onCancel,
    hasCancel,
    isWarningStyle,
    placeholder,
    styles,
  }: ITextFieldConfirmProps & OverlayProps) => {
    openModal({
      component: TextFieldConfirm,
      props: {
        cancelText,
        confirmText,
        description,
        regex,
        initialValue,
        errorText,
        title,
        onConfirm,
        onCancel,
        hasCancel,
        isWarningStyle,
        placeholder,
        style: {
          container: {
            width: '296px',
          },
        },
        styles,
      },
    });
  };

  return { confirm, textFieldConfirm };
};
