import Link from 'next/link';
import { ComponentProps } from 'react';
import {
  AsButtonStyle,
  AsButtonStyleProps,
} from '../asButtonStyle/AsButtonStyle';

export type ButtonLinkProps = ComponentProps<typeof Link> & AsButtonStyleProps;

export const ButtonLink = ({
  variant,
  size,
  fullWidth,
  ...props
}: ButtonLinkProps) => {
  return (
    <AsButtonStyle variant={variant} size={size} fullWidth={fullWidth}>
      <Link {...props} />
    </AsButtonStyle>
  );
};
