'use client';

import { Column } from '@vuddy/components';
import {
  ImageSizeKeys,
  size as designTokenSize,
  theme,
} from '@vuddy/components/theme';
import { jsonAssignInlineVars } from '@vuddy/utils';
import Image from 'next/image';
import { ReactNode, useState } from 'react';
import * as styles from './productThumbnail.css';

interface ProfileImageProps {
  imageSize: ImageSizeKeys | '100%';
  url: string;
  overlayText?: string;
  children?: ReactNode;
}

export const ProductThumbnail = ({
  imageSize,
  url,
  overlayText,
  children,
}: ProfileImageProps) => {
  const size = imageSize === '100%' ? '100%' : theme.size[`image_${imageSize}`];
  const nSize =
    imageSize === '100%'
      ? undefined
      : parseInt(designTokenSize[`image_${imageSize}`]);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className={styles.thumbnailWrapper}
      style={jsonAssignInlineVars({
        [styles.widthVar]: size,
        [styles.heightVar]: size,
        aspectRatio: imageSize === '100%' ? '1/1' : undefined,
      })}>
      <Image
        width={nSize}
        height={nSize}
        fill={imageSize === '100%'}
        objectFit="cover"
        style={jsonAssignInlineVars({
          [styles.heightVar]: size,
        })}
        onLoadingComplete={() => setIsLoaded(true)}
        src={url}
        alt="profile image"
      />
      {overlayText && <Column className={styles.overlay}>{overlayText}</Column>}
      {children}
    </div>
  );
};
