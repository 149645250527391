'use client';

import { HistoryIndexContext } from '@/components/providers/HistoryIndexProvider';
import webViewService from '@/lib/webViewService';
import { IconButton } from '@vuddy/components';
import { getClientSideUserAgent, isNonNil } from '@vuddy/utils';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';
import { INavigationBarProps, NavigationBar } from '../NavigationBar';

interface IBackNavigationBarProps extends INavigationBarProps {
  fallbackUrl?: string;
  onBackBtnClick?: VoidFunction;
}

export const BackNavigationBar = ({
  fallbackUrl,
  onBackBtnClick,
  ...props
}: IBackNavigationBarProps) => {
  const router = useRouter();
  const historyIndex = useContext(HistoryIndexContext);
  const handleBackBtnClick = () => {
    if (typeof onBackBtnClick === 'function') {
      onBackBtnClick();
    } else if (historyIndex > 0) {
      router.back();
    } else if (isNonNil(fallbackUrl)) {
      router.replace(fallbackUrl);
    } else if (webViewService.isAppWebView(getClientSideUserAgent())) {
      (document.activeElement as HTMLElement)?.blur();
      webViewService.close();
    } else {
      router.replace('/');
    }
  };

  return (
    <NavigationBar
      left={
        <IconButton
          icon={{ name: 'OUTLINE_ARROW_IOSBACK_24' }}
          onClick={handleBackBtnClick}
        />
      }
      {...props}
    />
  );
};
