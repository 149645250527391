import { env } from 'next-runtime-env';

const phase = env('NEXT_PUBLIC_PHASE');
const clickVuddyUrl = 'https://click.vuddy.io/vV4l';
const baseUrl = 'https://vuddy.onelink.me/vV4l';

export const oneLinks = {
  cardOpening: ({
    orderId,
    channelId,
  }: {
    orderId: number;
    channelId: number;
  }) => {
    if (phase === 'production') {
      return `${clickVuddyUrl}/zwwdtiy6?deep_link_sub1=order_id%3D${orderId}&deep_link_sub2=channel_id%3D${channelId}`;
    } else {
      return `${clickVuddyUrl}/8g9rgm2i?deep_link_sub1=order_id%3D${orderId}&deep_link_sub2=channel_id%3D${channelId}`;
    }
  },
  channelTab: ({
    channelId,
    tab,
  }: {
    channelId: number;
    tab: 'Shop' | 'MyBox' | 'Creator';
  }) => {
    if (phase === 'production') {
      return `${baseUrl}/j5gxnmso?deep_link_sub1=channel_id%3D${channelId}&deep_link_sub2=tab%3D${tab}`;
    } else {
      return `${baseUrl}/h6khezz6?deep_link_sub1=channel_id%3D${channelId}&deep_link_sub2=tab%3D${tab}`;
    }
  },
};
