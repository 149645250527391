'use client';

import { useModal } from '@/hooks/useModal';
import { handleError } from '@/lib/useWrappingQuery';
import { ApiResponseT } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/navigation';

export const QueryClientErrorBoundary = () => {
  const router = useRouter();
  const { confirm } = useModal();
  const queryClient = useQueryClient();
  queryClient.getQueryCache().config.onError = (error, query) => {
    const skipDefaultErrorHandling = query.meta?.skipDefaultErrorHandling;
    if (!skipDefaultErrorHandling) {
      handleError(error as AxiosError<ApiResponseT<unknown>>, confirm, router);
    }
  };

  return null;
};
