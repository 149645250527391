import clsx from 'clsx';
import ReactLoadingSkeleton, {
  SkeletonProps as ReactLoadingSkeletonProps,
} from 'react-loading-skeleton';
import { sprinkles } from '../sprinkles.css';

export interface SkeletonProps extends ReactLoadingSkeletonProps {
  flexGrow?: boolean;
}

export const Skeleton = ({ flexGrow = false, ...props }: SkeletonProps) => {
  return (
    <ReactLoadingSkeleton
      width="80px"
      height="100%"
      {...props}
      containerClassName={clsx(
        props.containerClassName,
        flexGrow ? sprinkles({ flex: 1 }) : undefined
      )}
    />
  );
};
