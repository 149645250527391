'use client';

import { Button, Input } from '@/components';
import { Column, Element, Row } from '@vuddy/components';
import { useEffect, useRef, useState } from 'react';

export interface ITextFieldConfirmProps {
  title?: string;
  description?: string;
  initialValue: string;
  regex?: RegExp;
  errorText?: string;
  maxLength?: number;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: (value: string) => void;
  onCancel?: VoidFunction;
  hasCancel?: boolean;
  isWarningStyle?: boolean;
  placeholder?: string;
  styles?: {
    wrapper?: (params?: { disabled: boolean; error: boolean }) => string;
    input?: (params?: { disabled: boolean; error: boolean }) => string;
  };
}

export const TextFieldConfirm = ({
  cancelText = '취소',
  confirmText = '확인',
  description,
  title,
  initialValue,
  regex,
  errorText,
  maxLength = 20,
  onConfirm,
  onCancel,
  onClose,
  hasCancel = false,
  isWarningStyle = false,
  placeholder,
  styles,
}: ITextFieldConfirmProps & { onClose: VoidFunction }) => {
  const [value, setValue] = useState(initialValue ?? '');
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const handleConfirm = () => {
    onConfirm?.(value);
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  const isInvalidValue = regex && !regex.test(value);

  return (
    <Column gap="gap_4xl" paddingX="padding_small" paddingY="padding_base">
      <Column gap="gap_base">
        <Row justifyContent="space-between">
          <Element typography="bodySmallAccent" color="primary">
            {title}
          </Element>
          <Element typography="captionBase" color="placeholder">
            {value.length}/{maxLength}
          </Element>
        </Row>

        <Element typography="bodyXs" color="tertiary">
          {description}
        </Element>

        <Input
          ref={ref}
          value={value}
          error={isInvalidValue}
          errorText={errorText}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={e => {
            setValue(e.target.value);
          }}
          onClear={() => setValue('')}
          styles={styles}
        />
      </Column>

      <Row gap="gap_small" flex={1}>
        {hasCancel && (
          <Button
            variant={isWarningStyle ? 'outlineSecondary' : 'solidTertiary'}
            onClick={handleCancel}
            fullWidth>
            {cancelText}
          </Button>
        )}
        <Button
          variant={isWarningStyle ? 'outlineDanger' : 'solidPrimary'}
          onClick={handleConfirm}
          disabled={isInvalidValue || value === initialValue}
          fullWidth>
          {confirmText}
        </Button>
      </Row>
    </Column>
  );
};
