'use client';

import {
  Row,
  Checkbox as SharedCheckbox,
  CheckboxProps as SharedCheckboxProps,
} from '@vuddy/components';
import { gapSize } from '@vuddy/components/theme';
import { uniqueId } from 'lodash-es';
import { ReactNode, useState } from 'react';
import * as styles from './checkbox.css';
import { CheckboxIcon } from './checkboxIcon';

interface ICheckboxProps extends Omit<SharedCheckboxProps, 'children'> {
  checked?: boolean;
  isPartialChecked?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onChange?: (checked: boolean) => void;
  gap?: keyof typeof gapSize;
}

export const Checkbox = ({
  id = uniqueId('checkbox_'),
  checked: controlledChecked,
  isPartialChecked,
  disabled = false,
  children,
  defaultChecked = false,
  onChange: setControlledChecked,
  gap = 'gap_base',
  ...props
}: ICheckboxProps) => {
  const [uncontrolledChecked, setUncontrolledChecked] =
    useState(defaultChecked);

  const checked = controlledChecked ?? uncontrolledChecked;
  const onChange = setControlledChecked ?? setUncontrolledChecked;

  const isChecked = checked || isPartialChecked;

  return (
    <SharedCheckbox
      id={id}
      checked={isChecked}
      disabled={disabled}
      onChange={onChange}
      {...props}>
      <Row className={styles.wrapper} gap={gap}>
        <CheckboxIcon
          checked={checked}
          isPartialChecked={isPartialChecked}
          disabled={disabled}
        />
        {children}
      </Row>
    </SharedCheckbox>
  );
};
