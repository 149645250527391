'use client';

import { Row } from '@vuddy/components';
import { Key } from 'react';
import { TabButton } from '../button';
import * as styles from './filter.css';

export interface IFilterItem<T = string> {
  id: T;
  name: string;
}

interface IFilterProps<T> {
  selectedId: T;
  setSelectedId: (id: T) => void;
  list: IFilterItem<T>[];
}

export const Filter = <T,>({
  selectedId,
  setSelectedId,
  list,
}: IFilterProps<T>) => {
  return (
    <Row
      paddingX="padding_small"
      paddingY="padding_3xs"
      gap="gap_small"
      overflow="scroll"
      className={styles.hideScrollbar}>
      {list.map(({ id, name }) => (
        <TabButton
          key={id as Key}
          onClick={() => setSelectedId(id)}
          enabled={id === selectedId}>
          {name}
        </TabButton>
      ))}
    </Row>
  );
};
