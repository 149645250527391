import { Element, ElementProps } from '../element';
import { LineToken } from '../theme/theme.css';
import * as styles from './divider.css';

interface DividerProps
  extends Pick<
    ElementProps,
    'marginX' | 'marginY' | 'borderWidth' | 'width' | 'height'
  > {
  color?: LineToken;
  direction?: 'horizontal' | 'vertical';
}
export const Divider = ({
  borderWidth = 'stroke_small',
  color = 'dividerPrimary',
  direction,
  width = '100%',
  height = direction === 'vertical' ? 12 : 1,
  ...props
}: DividerProps) => {
  return direction === 'vertical' ? (
    <Element className={styles.verticalDivider} height={height} />
  ) : (
    <Element
      borderColor={color}
      borderWidth={borderWidth}
      borderStyle="solid"
      width={width}
      height={height}
      {...props}
    />
  );
};
