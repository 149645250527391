import { deepLinks } from '@/components/deepLinks';
import { env } from 'next-runtime-env';

interface IGetRouterPathProps {
  isAppWebView?: boolean;
}

export const getRouterPath = (
  { isAppWebView }: IGetRouterPathProps = {
    isAppWebView: false,
  }
) => {
  return {
    home: isAppWebView ? deepLinks.home : '/',
    login: isAppWebView ? deepLinks.login : '/login',
    loginRedirect: (redirectUrl: string) =>
      isAppWebView ? deepLinks.login : `/login?redirectUrl=${redirectUrl}`,
    signupTerms: '/signup/terms',
    signupEmail: '/signup/email',
    signupEmailPincode: (emailAddress: string) =>
      `/signup/email/pincode?emailAddress=${emailAddress}`,
    ranking: '/ranking',
    my: '/my',
    myAccount: '/my/account',
    myAccountLeave: '/my/account/leave',
    creatorLeave: '/my/account/leave/creator',
    myAccountLeavePincode: (emailAddress: string) =>
      `/my/account/leave/pincode?emailAddress=${emailAddress}`,
    myAccountLeaveComplete: '/my/account/leave/complete',
    channelsChoice: '/channels/choice',
    orderList: '/orders',
    orderDetail: (orderId: number) => `/orders/${orderId}`,
    channels: '/channels',
    channelsCreator: ({
      channelUrl,
      channelId,
    }: {
      channelUrl: string;
      channelId?: number;
    }) =>
      isAppWebView && channelId
        ? deepLinks.channelDetail({ channelId, tab: 'Creator' })
        : `/channels/${channelUrl}/creator`,
    channelShop: ({
      channelUrl,
      channelId,
    }: {
      channelUrl: string;
      channelId?: number;
    }) =>
      isAppWebView && channelId
        ? deepLinks.channelDetail({ channelId, tab: 'Shop' })
        : `/channels/${channelUrl}`,
    delivery: '/delivery',
    newDelivery: '/delivery/new',
    deliveryDetail: (deliveryId: number | 'new') => `/delivery/${deliveryId}`,
    policies: '/my/policies',
    orderSheet: (
      channelId: number,
      salePageId: number,
      orderItemsStr: string,
      hasPhysical: boolean
    ) =>
      `/ordersheet/${salePageId}?channelId=${channelId}&hasPhysical=${hasPhysical}&orderItems=${orderItemsStr}`,
    orderDelivery: (deliveryId?: number) =>
      `/ordersheet/delivery${deliveryId ? `?deliveryId=${deliveryId}` : ''}`,
    notification: '/notification',
    saleDetail: (salePageId: number) => `/sales/${salePageId}`,
    comments: (salePageId: number) => `/sales/${salePageId}/comments`,
    endedSale: (salePageId: number) => `/sales/${salePageId}/ended`,
    notFound: '/not-found',
  };
};

export const studioRouterPath = {
  channelHome: (channelId: number) =>
    `${env('NEXT_PUBLIC_STUDIO_URL')}/channel/${channelId}`,
  channelCreate: `${env('NEXT_PUBLIC_STUDIO_URL')}/channel/create`,
  channelSelect: `${env('NEXT_PUBLIC_STUDIO_URL')}/channel`,
};

export const kcpRouterPath = {
  mobileReturnUrl: `${env('NEXT_PUBLIC_WEB_URL')}/sales/kcp-result`,
  mobilePaymentResult: `${env('NEXT_PUBLIC_WEB_URL')}/sales/payment-result`,
};
