'use client';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { ReactNode } from 'react';

export const QueryClientProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache(),
  });

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};
