import type { ApiResponseT } from '@/types';
import { getAccessTokenCookieName } from '@/utils/authToken';
import {
  getClientSideUserAgent,
  getCookie,
  isClientSide,
  isServerSide,
} from '@vuddy/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { env } from 'next-runtime-env';
import webViewService from './webViewService';

const getAuthorizationToken = (accessToken: string) => `Bearer ${accessToken}`;

const requestInstance = axios.create({
  baseURL: isServerSide ? `${env('API_URL')}/api/v1` : '/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

const requestNoAuthorizationInstance = axios.create({
  baseURL: isServerSide ? `${env('API_URL')}/api/v1` : '/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const mediaRequestInstance = axios.create({
  baseURL: `${env('NEXT_PUBLIC_MEDIA_API_URL')}/api/v1/resources`,
  headers: {
    'x-auth-service-name':
      env('NEXT_PUBLIC_PHASE') === 'sandbox' ? 'vuddy_sandbox' : 'vuddy',
    'Content-Type': 'multipart/form-data',
  },
});

export const setAuthorizationHeader = (token: string) => {
  requestInstance.defaults.headers['Authorization'] =
    getAuthorizationToken(token);
};

export const removeAuthorizationHeader = () => {
  delete requestInstance.defaults.headers['Authorization'];
};

export const setXDevicePlatform = (platform: string) => {
  requestInstance.defaults.headers['X-DEVICE-PLATFORM'] = platform;
};

[requestInstance, mediaRequestInstance].forEach(instance => {
  instance.interceptors.request.use(config => {
    const accessToken = getCookie(getAccessTokenCookieName());
    if (accessToken) {
      config.headers.Authorization = getAuthorizationToken(accessToken);
    }

    if (isClientSide) {
      const clientSideXDevicePlatform = webViewService.isAppWebView(
        getClientSideUserAgent()
      )
        ? webViewService.getDevicePlatform(getClientSideUserAgent())
        : 'WEB';
      config.headers['X-DEVICE-PLATFORM'] = clientSideXDevicePlatform;
      config.headers['Accept-Language'] = 'ko-KR';
    }

    return config;
  });

  instance.interceptors.response.use(res => {
    return res.data;
  });
});

export const request = {
  get: <T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.get(url, config),
  post: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.post(url, data, config),
  patch: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.patch(url, data, config),
  put: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.put(url, data, config),
  delete: <T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.delete(url, config),
};

export const requestNoAuthorization = {
  get: <T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.get(url, config),
  post: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.post(url, data, config),
  patch: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.patch(url, data, config),
  put: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.put(url, data, config),
  delete: <T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiResponseT<T>> => requestInstance.delete(url, config),
};

export const mediaRequest = {
  post: <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> =>
    mediaRequestInstance.post(url, data, config),
};
