import { Column, Element, ElementProps, Icon } from '@vuddy/components';
import { typography } from '@vuddy/components/theme';
import { isNonNil } from '@vuddy/utils';
import { ReactNode } from 'react';

interface IEmptyViewProps extends Pick<ElementProps, 'height'> {
  title?: ReactNode;
  description?: ReactNode;
  includeFilter?: boolean;
  children?: ReactNode;
  className?: string;
}

export const EmptyView = ({
  title,
  description,
  includeFilter = false,
  children,
  height,
  className,
}: IEmptyViewProps) => {
  return (
    <Column
      height={
        isNonNil(height)
          ? height
          : includeFilter
            ? 'web-fit-except-gnb-except-filter'
            : 'web-fit-except-gnb'
      }
      gap="gap_2xl"
      alignItems="center"
      justifyContent="center"
      className={className}>
      <Icon name="ETC_ERRORGRAPHIC_64" width="64px" height="64px" />
      <Column gap="gap_xs" alignItems="center">
        {isNonNil(title) && (
          <div className={typography.bodySmallAccent}>{title}</div>
        )}
        {description ? (
          <Element typography="bodySmall" color="tertiary" textAlign="center">
            {description}
          </Element>
        ) : null}
      </Column>
      {children}
    </Column>
  );
};
