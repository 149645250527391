import { getRouterPath } from '@/components/routerPath';
import webViewService from '@/lib/webViewService';
import { IChannelSimpleResponse } from '@/types';
import { Element } from '@vuddy/components';
import { getClientSideUserAgent } from '@vuddy/utils';
import Link from 'next/link';

interface IOrderChannelProps {
  channel: IChannelSimpleResponse;
}

export const OrderChannel = ({ channel }: IOrderChannelProps) => {
  const isAppWebView = webViewService.isAppWebView(getClientSideUserAgent());
  const routerPath = getRouterPath({
    isAppWebView,
  });

  return (
    <Link
      href={routerPath.channelShop({
        channelUrl: channel.url,
        channelId: channel.id,
      })}>
      <Element typography="captionBaseAccent" color="tertiary">
        {channel.name}
      </Element>
    </Link>
  );
};
