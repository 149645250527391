import { Column, Element } from '@vuddy/components';
import { surface, theme } from '@vuddy/components/theme';
import { hexToRgba, jsonAssignInlineVars } from '@vuddy/utils';
import { Children, Fragment, ReactElement, ReactNode } from 'react';
import * as styles from './cta.css';
import { BaseProps, ICtaProps } from './types';

const isFragmentElement = (child: ReactNode): child is ReactElement => {
  return (child as ReactElement).type === Fragment;
};

const getFragmentChildrenArray = (fragment: ReactElement) => {
  return Children.toArray(fragment.props.children);
};

const getChildrenArray = (children: ReactNode) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.length === 1 && isFragmentElement(childrenArray[0])
    ? getFragmentChildrenArray(childrenArray[0])
    : childrenArray;
};

const createGradient = (color: string) => {
  return `linear-gradient(to bottom, 
    ${hexToRgba(color, 0)} 0%,
    ${hexToRgba(color, 1)} 100%)`;
};

export const CTA = ({
  direction = 'vertical',
  ratio,
  description,
  children,
  position,
  backgroundColor = 'primary',
  gradient = false,
}: ICtaProps & BaseProps) => {
  const childrenArray = getChildrenArray(children);
  const flexArray = direction === 'vertical' ? ['1', '1'] : ratio!.split(':');
  const bgColor = surface[backgroundColor];
  const gradientBackground = createGradient(bgColor);

  return (
    <Column
      className={styles.wrapper({
        position,
        gradient,
      })}
      style={jsonAssignInlineVars({
        [styles.backgroundColorVar]: theme.surface[backgroundColor],
        [styles.gradientBackgroundVar]: gradientBackground,
      })}>
      {description && <div className={styles.description}>{description}</div>}
      <Element
        display="flex"
        flexDirection={direction === 'vertical' ? 'column' : 'row'}
        className={styles.ctaWrapper}>
        {childrenArray.map((childNode, index) => (
          <div key={index} style={{ flex: flexArray[index % 2] }}>
            {childNode}
          </div>
        ))}
      </Element>
    </Column>
  );
};
