'use client';

import { getRouterPath } from '@/components';
import { IConfirm, useModal } from '@/hooks/useModal';
import { ApiResponseT, IErrorResponse, IPaginatedResponse } from '@/types';
import { URLS } from '@/urls';
import { removeToken } from '@/utils/authToken';
import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useMutation as useOriginMutation,
  useQuery as useOriginQuery,
  useInfiniteQuery as useOriginalInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getClientSideUserAgent } from '@vuddy/utils';
import { AxiosError } from 'axios';
import { isEmpty, isNil } from 'lodash-es';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import webViewService from './webViewService';

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  params: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    skipDefaultErrorHandling?: boolean;
  }
) => {
  const { skipDefaultErrorHandling, ...restParams } = params;
  return useOriginQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...restParams,
    meta: {
      ...restParams.meta,
      skipDefaultErrorHandling,
    },
  });
};

export const useMutation = <
  TData = unknown,
  TError = AxiosError<ApiResponseT<TData>>,
  TVariables = void,
  TContext = unknown,
>(
  params: UseMutationOptions<TData, TError, TVariables, TContext> & {
    redirectPathOnSuccess?: string;
    skipDefaultErrorHandling?: (error: TError) => boolean;
  }
) => {
  const router = useRouter();
  const { confirm } = useModal();

  return useOriginMutation<TData, TError, TVariables, TContext>({
    ...params,
    onSuccess: (data, variables, context) => {
      params.onSuccess?.(data, variables, context);

      if (
        !isNil(params.redirectPathOnSuccess) &&
        !isEmpty(params.redirectPathOnSuccess)
      ) {
        router.push(params.redirectPathOnSuccess);
      }
    },
    onError: (error, variables, context) => {
      handleError<TData>(
        error as AxiosError<ApiResponseT<TData>>,
        confirm,
        router,
        {
          skipErrorConfirm: params.skipDefaultErrorHandling?.(error) ?? false,
        }
      );
      params.onError?.(error, variables, context);
    },
    retry: params.retry ?? false,
  });
};

const openErrorConfirm = (
  error: IErrorResponse,
  confirm: IConfirm,
  onConfirm?: VoidFunction,
  hasCancel = false,
  confirmText = '확인'
) => {
  if (error.displayType === 'DIALOG' || error.displayType === 'NONE') {
    confirm({
      title: error.title,
      description: error.message,
      onConfirm,
      hasCancel,
      confirmText,
    });
  }
};

export const handleError = <TData>(
  error: AxiosError<ApiResponseT<TData>>,
  confirm: IConfirm,
  router: AppRouterInstance,
  options: { skipErrorConfirm?: boolean } = {}
) => {
  if (isNil(error.response)) {
    console.error(error);
    return;
  }

  const { status, data } = error.response;

  if (status === 401 || status === 403) {
    removeToken();

    const isAppWebView = webViewService.isAppWebView(getClientSideUserAgent());
    if (isAppWebView) {
      webViewService.refreshToken(
        window.location.pathname + window.location.search
      );
    } else if (!options.skipErrorConfirm) {
      if (data.error.errorCode === -40001) {
        openErrorConfirm(
          data.error,
          confirm,
          () => {
            router.push(URLS.INQUIRY);
          },
          true,
          '고객센터 문의'
        );
      } else {
        openErrorConfirm(data.error, confirm, () => {
          router.push(getRouterPath().login);
        });
      }
    }
  } else if (!options.skipErrorConfirm) {
    openErrorConfirm(data.error, confirm);
  }
};

export const useInfiniteQuery = <T>(
  options: Omit<
    UseInfiniteQueryOptions<
      ApiResponseT<IPaginatedResponse<T>>,
      unknown,
      {},
      ApiResponseT<IPaginatedResponse<T>>,
      QueryKey,
      string | undefined
    >,
    'onError' | 'queryKey' | 'initialPageParam' | 'getNextPageParam'
  > & {
    queryKey: QueryKey;
    maintainDataOnUnmount?: boolean;
  }
) => {
  const queryClient = useQueryClient();

  const { maintainDataOnUnmount, ...restOptions } = options;

  useEffect(() => {
    // unmount 될때 maintainDataOnUnmount option이 설정되어 있지 않으면
    // 데이터가 남아있지 않도록 쿼리를 날려준다.
    return () => {
      if (!maintainDataOnUnmount) {
        queryClient.removeQueries({
          queryKey: restOptions.queryKey,
        });
      }
    };
  }, []);

  return useOriginalInfiniteQuery({
    ...restOptions,
    initialPageParam: undefined,
    getNextPageParam: (lastPage, _pages) => lastPage.data.cursor.next,
    select: data => ({
      pages: data.pages.map(page => page.data.list).flat(),
      pageParams: data.pages[data.pages.length - 1].data.cursor.next,
    }),
    retry: options.retry ?? false,
  });
};
