'use client';

import webViewService from '@/lib/webViewService';
import { IconButton } from '@vuddy/components';
import { getClientSideUserAgent, isMobile, SECOND } from '@vuddy/utils';
import { env } from 'next-runtime-env';
import { usePathname, useSearchParams } from 'next/navigation';
import qs from 'qs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

export const ShareButton = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });
  const url = `${env('NEXT_PUBLIC_DOMAIN')}${pathname}${queryString}`;

  const handleClick = () => {
    if (webViewService.isAppWebView(getClientSideUserAgent())) {
      webViewService.shareUrl(url);
    } else if (navigator.canShare()) {
      window.navigator.share({ url });
    }
  };

  const showCopiedToast = () => {
    toast.info('링크가 복사되었습니다.', {
      position: 'top-center',
      toastId: 'copy-link',
      autoClose: 3 * SECOND,
    });
  };

  return isMobile(getClientSideUserAgent()) ? (
    <IconButton
      icon={{ name: 'OUTLINE_SHARE_24', size: '24px', color: 'primary' }}
      onClick={handleClick}
    />
  ) : (
    <CopyToClipboard text={url} onCopy={showCopiedToast}>
      <IconButton
        icon={{ name: 'OUTLINE_SHARE_24', size: '24px', color: 'primary' }}
      />
    </CopyToClipboard>
  );
};
