import { Column } from '../flex';
import { LoadingSpinner } from './LoadingSpinner';

interface IFullScreenLoadingSpinnerProps {
  condition: boolean;
  includeFilter?: boolean;
  children: React.ReactNode;
  spinnerCondition?: boolean;
  spinnerConditionChildren?: React.ReactNode;
}

export const FullScreenLoadingSpinner = ({
  condition,
  includeFilter = false,
  children,
  spinnerCondition = true,
  spinnerConditionChildren,
}: IFullScreenLoadingSpinnerProps) => {
  return condition ? (
    children
  ) : spinnerCondition ? (
    <Column
      width="100%"
      height={
        includeFilter
          ? 'web-fit-except-gnb-except-filter'
          : 'web-fit-except-gnb'
      }
      alignItems="center"
      justifyContent="center">
      <LoadingSpinner />
    </Column>
  ) : (
    spinnerConditionChildren
  );
};
