import Link from 'next/link';

interface IIntegrationLinkProps {
  isLink?: boolean;
  isExternal: boolean;
  link: string;
  children: React.ReactNode;
}

export const IntegrationLink = ({
  isLink,
  isExternal,
  link,
  children,
}: IIntegrationLinkProps) => {
  return isLink ? (
    isExternal ? (
      <a href={link} target="_blank">
        {children}
      </a>
    ) : (
      <Link href={link} passHref>
        {children}
      </Link>
    )
  ) : (
    children
  );
};
