'use client';

import { Row } from '@vuddy/components';
import { gapSize } from '@vuddy/components/theme';
import clsx from 'clsx';
import { ChangeEvent, ReactNode, useState } from 'react';
import * as styles from './radio.css';

interface RadioProps {
  name?: string;
  value: string | number;
  label: ReactNode;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  gap?: keyof typeof gapSize;
}

export const Radio = ({
  name,
  value,
  label,
  checked: controlledChecked,
  defaultChecked = false,
  onChange: controlledOnChange,
  disabled = false,
  gap = 'gap_base',
  ...props
}: RadioProps) => {
  const [innerChecked, setInnerChecked] = useState(defaultChecked);

  const checked = controlledChecked ?? innerChecked;
  const onChange =
    controlledOnChange ??
    ((e: ChangeEvent<HTMLInputElement>) => setInnerChecked(e.target.checked));

  const id = name ? `${name}-${value}` : `radio-${value}`;

  return (
    <label
      htmlFor={id}
      className={clsx(styles.radioLabel, disabled && styles.disabledLabel)}>
      <Row alignItems="center" width="100%" gap={gap}>
        <span
          className={clsx(
            styles.radioContainer,
            disabled && styles.disabledContainer
          )}>
          <input
            id={id}
            type="radio"
            className={styles.radio}
            value={value}
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
          <span className={styles.customRadio} />
        </span>
        {label}
      </Row>
    </label>
  );
};
