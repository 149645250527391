import { env } from 'next-runtime-env';
import qs from 'qs';

const getScheme = () => {
  const phase = env('NEXT_PUBLIC_PHASE');
  switch (phase) {
    case 'dev':
      return 'vuddydev';
    case 'sandbox':
      return 'vuddysandbox';
    default:
      return 'vuddy';
  }
};
const SCHEME = getScheme();

const getUrl = (url: string, params?: Record<string, unknown>) => {
  const baseUrl = `${SCHEME}://${url}`;
  if (params && Object.keys(params).length > 0) {
    return `${baseUrl}?${qs.stringify(params)}`;
  }
  return baseUrl;
};

export const deepLinks = {
  home: getUrl('home'),
  login: getUrl('login'),
  cardOpening: (params: { orderId: number; channelId: number }) =>
    getUrl('open_results', {
      order_id: params.orderId,
      channel_id: params.channelId,
    }),
  channelDetail: (params: {
    channelId: number;
    tab: 'Shop' | 'MyBox' | 'Creator';
  }) =>
    getUrl('channel', {
      channel_id: params.channelId,
      tab: params.tab,
    }),
};
