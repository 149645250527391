import { Button } from '@/components';
import { Column, Element, IconButton, Row } from '@vuddy/components';
import { theme } from '@vuddy/components/theme';
import { isNonNil, jsonAssignInlineVars } from '@vuddy/utils';
import { ReactNode } from 'react';
import * as styles from './confirm.css';

export interface IConfirmProps {
  title?: ReactNode;
  description?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
  hasConfirm?: boolean;
  hasCloseButton?: boolean;
  hasCancel?: boolean;
  isWarningStyle?: boolean;
}

export const Confirm = ({
  cancelText = '취소',
  confirmText = '확인',
  description,
  title,
  onConfirm,
  onCancel,
  onClose,
  hasConfirm = true,
  hasCloseButton = false,
  hasCancel = false,
  isWarningStyle = false,
}: IConfirmProps & { onClose: VoidFunction }) => {
  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Column
      width="100%"
      gap="gap_4xl"
      paddingX="padding_small"
      className={styles.wrapper({ hasCloseButton })}>
      <div>
        {hasCloseButton && (
          <Row justifyContent="flex-end">
            <Element width={24}>
              <IconButton
                onClick={handleClose}
                icon={{ name: 'OUTLINE_CLOSE_24' }}
              />
            </Element>
          </Row>
        )}
        <Column textAlign="center" gap="gap_xs">
          {isNonNil(title) && (
            <div
              className={styles.text({
                typography: 'bodyBaseAccent',
              })}
              style={jsonAssignInlineVars({
                [styles.colorVar]: theme.text.primary,
              })}>
              {title}
            </div>
          )}
          {isNonNil(description) && (
            <div
              className={styles.text({
                typography: 'bodyXs',
              })}
              style={jsonAssignInlineVars({
                [styles.colorVar]: theme.text.tertiary,
              })}>
              {description}
            </div>
          )}
        </Column>
      </div>
      {hasConfirm && (
        <Row gap="gap_small" flex={1}>
          {hasCancel && (
            <Button
              variant={isWarningStyle ? 'outlineSecondary' : 'solidTertiary'}
              onClick={handleCancel}
              fullWidth>
              {cancelText}
            </Button>
          )}
          <Button
            variant={isWarningStyle ? 'outlineDanger' : 'solidPrimary'}
            onClick={handleConfirm}
            fullWidth>
            {confirmText}
          </Button>
        </Row>
      )}
    </Column>
  );
};
