import { jsonAssignInlineVars } from '@vuddy/utils';
import clsx from 'clsx';
import { Element, ElementProps } from '../element';
import * as styles from './ellipsis.css';

interface IEllipsisProps extends ElementProps {
  children?: React.ReactNode;
  className?: string;
  lineClamp?: number;
}

export const Ellipsis = ({
  children,
  lineClamp = 1,
  className,
  ...props
}: IEllipsisProps) => {
  return (
    <Element
      className={clsx(styles.wrapper, className)}
      style={jsonAssignInlineVars({
        [styles.lineClampVar]: lineClamp,
      })}
      {...props}>
      {children}
    </Element>
  );
};
