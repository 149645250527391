import { OrderHistoryItemDetailStatus, OrderReferenceType } from '@/types';

const orderProductType = {
  BASIC_MEDIA: '디지털 상품',
  PHYSICAL: '실물 상품',
};

export const getOrderProductTypeText = (type: OrderReferenceType): string =>
  orderProductType[type];

const orderProductStatus: {
  [key in OrderHistoryItemDetailStatus]: string;
} = {
  PROVIDING_IN_PGORESS: '상품 지급중',
  PROVIDING_COMPLETED: '상품 지급 완료',
  PROVIDING_CANCELED: '상품 지급 취소',
  PAYMENT_COMPLETED: '결제 완료',
  ITEM_PREPARING: '상품 준비중',
  DELIVERY_IN_PROGRESS: '배송중',
  ITEM_DELIVERED: '배송 완료',
  PURCHASE_CONFIRMED: '구매 확정',
  CANCELED: '구매 취소',
} as const;

export const getOrderProductStatusText = (
  status: OrderHistoryItemDetailStatus
): string => orderProductStatus[status];
