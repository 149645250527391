'use client';

import {
  ImageSizeKeys,
  size as designTokenSize,
  theme,
} from '@vuddy/components/theme';
import { jsonAssignInlineVars } from '@vuddy/utils';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';
import DefaultProfileImage from './defaultProfile.svg';
import * as styles from './profileImage.css';

interface ProfileImageProps {
  imageSize: ImageSizeKeys;
  url?: string;
}

export const ProfileImage = ({ imageSize, url }: ProfileImageProps) => {
  const size = theme.size[`image_${imageSize}`];
  const nSize = parseInt(designTokenSize[`image_${imageSize}`]);
  const [isLoaded, setIsLoaded] = useState(false);

  const defaultProfile = (
    <div
      className={styles.size}
      style={jsonAssignInlineVars({
        [styles.sizeVar]: size,
      })}>
      {(!url || !isLoaded) && (
        <DefaultProfileImage width={nSize} height={nSize} />
      )}
      {url && (
        <Image
          width={nSize}
          height={nSize}
          objectFit="cover"
          className={clsx(styles.size, styles.profile)}
          style={jsonAssignInlineVars({
            [styles.sizeVar]: size,
          })}
          onLoadingComplete={() => setIsLoaded(true)}
          src={url}
          alt="profile image"
        />
      )}
    </div>
  );

  return defaultProfile;
};
