'use client';

import {
  useRequestEmailPincode,
  useVerifyEmailPincode,
} from '@/app/signup/email/lib';
import { BackNavigationBar, Button, InputCodes } from '@/components';
import { useModal } from '@/hooks/useModal';
import {
  IPincodeCreateResponse,
  IPincodeVerifyResponse,
  PincodeAddressType,
  PincodePurposeType,
} from '@/types';
import { Column, Element, Row } from '@vuddy/components';
import { isNonNil, SECOND } from '@vuddy/utils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as styles from './pincodeInputModal.css';

interface IPincodeInputProps {
  emailAddress: string;
  purpose: PincodePurposeType;
  remainedSeconds: number;
  onClose: VoidFunction;
  onSubmit: (data: IPincodeVerifyResponse) => void;
}

const PINCODE_REGEX = /^[0-9]$/;
const PINCODE_LENGTH = 6;
const ADDRESS_TYPE: PincodeAddressType = 'EMAIL';
const PINCODE_RESENDABLE_TIME = 12 * 60;

export const PincodeInputModal = ({
  emailAddress,
  purpose,
  remainedSeconds,
  onClose,
  onSubmit,
}: IPincodeInputProps) => {
  const { confirm } = useModal();

  const [remainingPincodeTime, setRemainingPincodeTime] =
    useState(remainedSeconds);
  const [isPincodeExpired, setIsPincodeExpired] = useState(false);

  const { mutate: verifyPincode } = useVerifyEmailPincode({
    onSuccess: (data: IPincodeVerifyResponse) => {
      onSubmit(data);
    },
  });

  const { mutate: resendPincode } = useRequestEmailPincode({
    onSuccess: (data: IPincodeCreateResponse) => {
      setRemainingPincodeTime(data.remainSeconds);
      toast.info('재발송했습니다. 이메일을 확인해주세요.', {
        position: 'bottom-center',
        toastId: 'resend-pincode',
        autoClose: 3 * SECOND,
      });
    },
  });

  const formatTime = (seconds: number) => {
    if (seconds <= 0) return '00:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  useEffect(() => {
    if (isNonNil(remainedSeconds)) {
      if (remainingPincodeTime > 0) {
        const timerId = setInterval(() => {
          setRemainingPincodeTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId);
      } else {
        setIsPincodeExpired(true);
      }
    }
  }, [remainedSeconds]);

  const handleCodesChange = (code: string) => {
    const isCodeFullyFilled = code.length === PINCODE_LENGTH;
    if (isCodeFullyFilled) {
      verifyPincode({
        address: emailAddress!,
        addressType: ADDRESS_TYPE,
        purpose,
        code,
      });
    }
  };

  const handleCodesResend = () => {
    const isResendable = remainingPincodeTime < PINCODE_RESENDABLE_TIME;

    if (isResendable) {
      resendPincode({
        purpose,
        address: emailAddress,
        addressType: 'EMAIL',
      });
    } else {
      confirm({
        title: (
          <Element typography="bodyBaseAccent" color="primary">
            인증코드는 최초 발송 3분 후<br />
            재발송 가능합니다.
          </Element>
        ),
      });
    }
  };

  const handleNotReceivedEmailBtnClick = () => {
    confirm({
      title: '인증메일을 받지 못하셨나요?',
      description: (
        <>
          이메일 주소가 정확한지 확인해주세요. <br />
          메일 서비스에 따라 시간이 소요될 수 있어요. <br />
          스팸함을 확인해 볼 수 있어요.
        </>
      ),
    });
  };

  return (
    <Column className={styles.modalWrapper}>
      <BackNavigationBar onBackBtnClick={onClose} />
      <Column gap="gap_base" marginBottom="gap_5xl">
        <Element typography="titleBase" color="primary" textAlign="center">
          이메일 주소로 전송된
          <br />
          6자리 인증코드를 입력해주세요
        </Element>
        <Element typography="bodyXsAccent" color="primary" textAlign="center">
          {emailAddress}
        </Element>
      </Column>

      <Column gap="gap_6xl" alignItems="center">
        <Column gap="gap_4xl" alignItems="center">
          <InputCodes
            regex={PINCODE_REGEX}
            length={PINCODE_LENGTH}
            onChange={handleCodesChange}
          />

          <Column gap="gap_large">
            {isPincodeExpired && (
              <Element typography="captionBaseAccent" color="danger">
                인증 코드가 만료되었습니다.
              </Element>
            )}

            <Row gap="gap_base" alignItems="center">
              <Element typography="bodyXsAccent" color="vuddyPrimary">
                {formatTime(remainingPincodeTime)}
              </Element>
              <Button
                size="small"
                variant="solidTertiary"
                onClick={handleCodesResend}>
                <Element typography="captionBase" color="tertiary">
                  재발송
                </Element>
              </Button>
            </Row>
          </Column>
        </Column>

        <Button
          size="xs"
          variant="solidTertiary"
          onClick={handleNotReceivedEmailBtnClick}>
          <Element typography="captionSmallAccent" color="tertiary">
            인증메일을 받지 못하셨나요?
          </Element>
        </Button>
      </Column>
    </Column>
  );
};
