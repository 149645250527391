type DEVICE_PLATFORM = 'AOS' | 'IOS' | 'WEB';
const MIN_ANDROID_APP_SCHEME_WORKING_VERSION = '2.5.0';

declare global {
  interface Window {
    AndroidBridge: IAndroidBridge;
    webkit?: IWebkit;
  }
}

interface IMessage {
  type: string;
  data?: unknown;
}

interface IAndroidBridge {
  postMessage: (message: string) => void;
  close: VoidFunction;
}

interface IWebkit {
  messageHandlers: {
    vuddy: {
      postMessage: (params: string) => void;
      close: VoidFunction;
    };
  };
}

interface IWebViewService {
  isAppWebView: (userAgent: string) => boolean;
  getDevicePlatform: (userAgent: string) => DEVICE_PLATFORM;
  refreshToken: (fullUrl: string) => void;
  close: VoidFunction;
  shareUrl: (url: string) => void;
  postMessage: ({ type, data }: IMessage) => void;
}

const isAppWebView = (userAgent: string) => {
  /* vuddy/AOS|iOS/(appVersion)/(languageCode) */
  return /vuddy\/(aos|ios)/i.test(userAgent);
};

const postMessage = ({ type, data }: IMessage) => {
  const message: IMessage = { type, data };
  const messageString = JSON.stringify(message);

  window.AndroidBridge?.postMessage(messageString);
  window.webkit?.messageHandlers.vuddy.postMessage(messageString);
};

const webViewService: IWebViewService = {
  isAppWebView,
  getDevicePlatform: userAgent => {
    if (/vuddy\/aos/i.test(userAgent)) {
      return 'AOS';
    } else if (/vuddy\/ios/i.test(userAgent)) {
      return 'IOS';
    } else {
      return 'WEB';
    }
  },
  refreshToken: fullUrl => {
    postMessage({ type: 'InvalidateAccessToken', data: fullUrl });
  },
  close: () => {
    postMessage({ type: 'Close' });
  },
  shareUrl: (url: string) => {
    postMessage({ type: 'ShareUrl', data: url });
  },
  postMessage,
};

export default webViewService;
