'use client';

import { Row } from '@vuddy/components';
import React, { useRef, useState } from 'react';
import { InputCodeBox } from './inputCodeBox';

interface IInputCodesProps {
  regex?: RegExp;
  length?: number;
  onChange: (code: string) => void;
}

export const InputCodes = ({
  regex = /^[0-9]$/,
  length = 6,
  onChange,
}: IInputCodesProps) => {
  const [code, setCode] = useState<string[]>(Array(length).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const focusNextInputCodeBox = (index: number) => {
    inputsRef.current[index + 1]?.focus();
  };

  const focusPrevInputCodeBox = (index: number) => {
    inputsRef.current[index - 1]?.focus();
  };

  const handleChange = (value: string, index: number) => {
    const isValidValue = regex.test(value);
    const shouldFocusNextInput = index < length - 1 && value !== '';
    const isLastIndex = index === length - 1 && value !== '';

    if (isValidValue) {
      const newCode = code.toSpliced(index, 1, value);
      setCode(newCode);
      onChange(newCode.join(''));

      if (shouldFocusNextInput) {
        focusNextInputCodeBox(index);
      }

      if (isLastIndex) {
        inputsRef.current[index]?.blur();
      }
    }
  };

  const handleDelete = (index: number) => {
    const shouldDeletePrevIndex = code[index] === '' && index > 0;
    const newCode = shouldDeletePrevIndex
      ? code.toSpliced(index - 1, 1, '')
      : code.toSpliced(index, 1, '');

    setCode(newCode);
    onChange(newCode.join(''));

    if (shouldDeletePrevIndex) {
      focusPrevInputCodeBox(index);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const isBackspaceKey = e.key === 'Backspace';
    const isReplaceWithNewNumber =
      !isBackspaceKey && inputsRef.current[index]?.value !== '';

    if (isBackspaceKey) {
      handleDelete(index);
    }

    if (isReplaceWithNewNumber) {
      handleChange(e.key, index);
    }
  };

  return (
    <Row gap="gap_small" justifyContent="center" alignItems="center">
      {code.map((num, index) => (
        <InputCodeBox
          key={index}
          value={num}
          onChange={e => handleChange(e.target.value, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          ref={el => {
            inputsRef.current[index] = el;
          }}
        />
      ))}
    </Row>
  );
};
