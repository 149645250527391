'use client';

import { useViewportSize } from '@/hooks/useViewportSize';
import WebViewService from '@/lib/webViewService';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { OverlayProvider, ZIndexProvider } from '@vuddy/components';
import { getClientSideUserAgent, SECOND } from '@vuddy/utils';
import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import { HistoryIndexProvider } from './HistoryIndexProvider';
import { QueryClientErrorBoundary } from './QueryClientErrorBoundary';
import { QueryClientProvider } from './QueryClientProvider';

export const Providers = ({
  children,
  maxWidth,
}: {
  children: ReactNode;
  maxWidth?: string;
}) => {
  useViewportSize(WebViewService.isAppWebView(getClientSideUserAgent()));

  return (
    <ZIndexProvider>
      <QueryClientProvider>
        <OverlayProvider maxWidth={maxWidth}>
          <QueryClientErrorBoundary />
          <HistoryIndexProvider>{children}</HistoryIndexProvider>
          <ToastContainer
            autoClose={2 * SECOND}
            position="bottom-center"
            hideProgressBar={true}
            icon={false}
            closeButton={false}
          />
          <ReactQueryDevtools />
        </OverlayProvider>
      </QueryClientProvider>
    </ZIndexProvider>
  );
};
